import Onboarding from "@package/steps";
import { parseStep } from "@utils/onboarding";
import {
  templateValidationsMessages,
  validateTemplateMessages,
} from "@utils/template-validations/templateValidationsMessages";
import isEqual from "lodash.isequal";

import {
  formatListContainsOnlyOneItem,
  templateStepsOrderValidationsRules,
  validateTemplateStepsOrderBasedOnEachFlow,
} from "../utils";

const { steps } = Onboarding;

export const usingTermsStepMustHaveAtLeastOneRequiredUseTerms = (
  onboardingSteps = [],
  templateValidationNotificationsToAdd = () => [],
) => {
  const documentTypeSteps = onboardingSteps.filter((_step) =>
    isEqual(parseStep(_step?.name)[0], steps.USING_TERMS.name),
  );
  const termsByStep = documentTypeSteps.map((step) => step?.terms ?? []);

  const templateHasAtLeastOneUseTermsRequired = termsByStep?.every((terms) => terms?.some((term) => term?.required));

  if (!templateHasAtLeastOneUseTermsRequired) {
    templateValidationNotificationsToAdd.push(
      validateTemplateMessages(templateValidationsMessages.TEMPLATE_HAS_NOT_ONE_USE_TERMS_REQUIRED_USING_TERMS),
    );
  }
};

/**
 * Validates the onboarding steps to ensure that the "Using Terms" step is correctly positioned
 * relative to the "Document Reuse" step and adds appropriate validation notifications if required.
 *
 * @param {Array} onboardingSteps - The list of onboarding steps.
 * @param {Array} usingTermsFlowsStepsIndexes - The indexes of the "Using Terms" steps in the flows.
 * @param {Array} documentReuseFlowsStepsIndexes - The indexes of the "Document Reuse" steps in the flows.
 * @param {Function} templateValidationNotificationsToAdd - Function to add validation notifications.
 *
 * @returns {void}
 *
 * @description
 * - Validates the order of "Using Terms" and "Document Reuse" steps based on each flow.
 * - Checks if the "Document Reuse" step exists without a corresponding "Using Terms" step and adds a validation notification.
 * - Adds a validation notification if the "Using Terms" step is in front of the "Document Reuse" step in any flow.
 * - Adds a validation notification if the flow reuse has the "DD" step.
 */
export const usingTermsStepMustHaveDocumentReuseIfRequired = (
  onboardingSteps = [],
  usingTermsFlowsStepsIndexes = [],
  documentReuseFlowsStepsIndexes = [],
  templateValidationNotificationsToAdd = () => [],
) => {
  // Validate the order of "Using Terms" and "Document Reuse" steps based on each flow.
  const usingTermsIsInFrontOfDocumentReuseValidations = validateTemplateStepsOrderBasedOnEachFlow(
    [usingTermsFlowsStepsIndexes, documentReuseFlowsStepsIndexes],
    templateStepsOrderValidationsRules.BETWEEN_TWO_STEPS,
  );

  // Get the "Document Reuse" steps.
  const documentReuseStep = onboardingSteps.filter((_step) =>
    isEqual(parseStep(_step?.name)[0], steps.DOCUMENT_REUSE.name),
  );

  // Get the "Using Terms" steps.
  const usingTermsSteps = onboardingSteps.filter((_step) => isEqual(parseStep(_step?.name)[0], steps.USING_TERMS.name));

  // Check if the "Document Reuse" step exists without a corresponding "Using Terms" step.
  if (documentReuseStep?.length === 1 && usingTermsSteps?.length === 0) {
    templateValidationNotificationsToAdd.push(
      validateTemplateMessages(templateValidationsMessages.TEMPLATE_HAS_NO_REQUIRED_USING_TERMS_DOCUMENT_REUSE),
    );
  }

  // Check if the flow reuse has the "DD" step.
  const ddStep = onboardingSteps.filter(
    (_step) => isEqual(parseStep(_step?.name)[0], steps.DD.name) && _step?.flow?.includes("REUSE"),
  );

  if (ddStep?.length) {
    templateValidationNotificationsToAdd.push(
      validateTemplateMessages(templateValidationsMessages.FLOW_REUSE_HAS_DD_STEP),
    );
  }

  // Check if the "Using Terms" step is in front of the "Document Reuse" step in any flow.
  if (!usingTermsIsInFrontOfDocumentReuseValidations?.length) {
    return;
  }

  // Get the flow names where the "Using Terms" step is in front of the "Document Reuse" step.
  let usingTermsIsInFrontOfDocumentReuseFlows = usingTermsIsInFrontOfDocumentReuseValidations?.map(
    (validation) => validation?.flow,
  );

  // Check if the "Using Terms" step is in front of the "Document Reuse" step in any flow.
  if (
    !!usingTermsIsInFrontOfDocumentReuseFlows?.length &&
    usingTermsIsInFrontOfDocumentReuseFlows?.every((flowName) => !!flowName)
  ) {
    usingTermsIsInFrontOfDocumentReuseFlows = formatListContainsOnlyOneItem(usingTermsIsInFrontOfDocumentReuseFlows);

    templateValidationNotificationsToAdd.push(
      validateTemplateMessages(templateValidationsMessages.USING_TERMS_IS_IN_FRONT_OF_DOCUMENT_REUSE),
    );
  }
};
